// THIS FILE IS GENERATED BY 'npm run set-env-variables'

export const environment = {
  production: true,

  // Configuration/Feature-switches:
  envName: '',
  useServiceWorker: true,
  useOffers: false,
  useQandAs: true,
  useQandASearch: true,
  useRegionPerLocale: false,
  useFeedbackPrompt: true,

  // Configuration of content
  localeLanguage: 'en',
  localeDir: 'ltr',
  localeAlternatives: 'en:English, nl:Nederlands, fr:Français, ar:العربية, pt:Português,  *:Other...',
  localeAlternativesExplanation: '* Translations via Google',
  appName: 'FAQ \n Vraagbaak',
  appLogoUrl: 'data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"%3E%3Cg fill="%23f5333f"%3E%3Cpath d="M10 0h10v30H10z" /%3E%3Cpath d="M30 10v10H0V10z" /%3E%3C/g%3E%3C/svg%3E',
  mainPageHeader: 'This web app is made to give helpful information (HIA) about frequently asked questions (FAQ).',
  mainPageIntroduction: 'Please select your FAQ below:\n \n',
  mainPageNotification: '',
  pageAbout: '## About \n \n This website is developed by the [Netherlands Red Cross](https://www.rodekruis.nl/en/).\n \n On this website you can find information about local aid services for undocumented people and about their rights.\n The information will help to improve the access to basic needs such as medical care, shelter, food and clothing, and lower the stress over daily survival.\n \n For any questions or remarks about this website: please send a WhatsApp message to [+31 970 10 28 69 64](https://wa.me/3197010286964).\n \n',
  pagePrivacy: '## Privacy \n \n - No data is gathered that can be directly linked to a specific person or IP-address.\n - No cookies or long-term browser-storage is used to track any (returning) visit over time.\n - Only anonymous logs of visits are collected and stored for maximum 1 year in a database controlled by the [Netherlands Red Cross](https://www.rodekruis.nl/en/).\n \n --- \n \n _Last update: 2024-06-12_ \n',
  mainFooter: '<div class="d-flex d-flex-align-end d-flex-justify-between"><div class="markdown">\n\n **Helpful Information**  \n\n [About](/about)  \n\n [Privacy](/privacy)  \n\n [WhatsApp Helpdesk](https://wa.me/3197010286964)  \n\n </div><div><a href="https://www.rodekruis.nl/" target=_blank rel="author noopener noreferrer" class="d-flex text-area--level ion-padding"><img src="https://rodekruis.github.io/510-ICON-LIBRARY/Beeldmerk_van_het_Nederlandse_Rode_Kruis.svg" alt="" with=86 height=32 decoding=async loading=lazy></a></div></div>',
  errorHeader: 'Data Unavailable',
  errorMessage: 'Reach out to us at: ',
  errorContactUrl: 'https://www.rodekruis.nl/en/contact/',
  errorRetry: 'Try again?',

  // Regions:
  regionConfig: '',
  regions: 'ukraine, shelter, undocumented-migrants, eu-migrants,',
  regionsLabels: 'Ukraine (EN), Asylum seeker (EN), Undocumented Migrants, EU Migrants,',
  regionsSheetIds: '14NZwDa8DNmH1q2Rxt-ojP9MZhJ-2GlOIyN8RF19iF04, 15yXp_w0chnFRwIfvY6uRgs7TwzxwuBz9iz-dgDFHsbs, 1Veli11xRUzIbmfTF8N57xAZqnMWcH1yKVP63PpfXF7s, 1SCyJ8v7blSDaeehWuImaRvwXMXC-9o7kbC5bnmotAYw,',

  // Third-party tokens:
  // Google Sheets API:
  google_sheets_api_key: 'AIzaSyCe9bIK2yLJTvRWGoYgaT5H7Lng1WBi6po',
  google_sheets_api_url: 'https://sheets.googleapis.com/v4/spreadsheets',

  // Application Insights
  appInsightsConnectionString: 'InstrumentationKey=d9a21705-a3bd-4ec6-bcaf-b1659f44f2cf;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/;ApplicationId=c46dc990-5bd4-4f4d-a13b-c8b673c6f7f7',
};
